.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.d-flex {
  display: flex!important;
  height: 80vh;
}

.center {
  margin: 0 auto;
  align-self: center;
}

/* make the customizations */
$theme-colors: (
  // "info": tomato,
  // "danger": teal,
  "breadcrumb" : #e9ecef
);

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),    //4px
    2: ($spacer * .5),     //8px
    3: $spacer,            //16px
    4: ($spacer * 1.5),    //24px
    5: ($spacer * 3),      //48px
    6: ($spacer * 4),      //64px
    7: ($spacer * 5),      //80px
    8: ($spacer * 6.25),   //100px
    9: ($spacer * 7.5),    //120px
    10: ($spacer * 9.375), //150px
    15: ($spacer * 18)     //300px
  ),
  $spacers
  );

.form-control:disabled, .form-control[readonly] {
  background-color: rgba(249, 249, 249, 255) !important;
}

@import "~bootstrap/scss/bootstrap";

.tlaComponent {
  height: 600px;
  float: left;
  margin-right: 6px;
  border-width: 1px;
  border-color: map-get($theme-colors, "primary");
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.listDetailComponents{
  display: row;
  flex-direction: row;
}
.bodyList{
  flex-direction: column;
}
.thList{
  flex-direction: row;
}
.button{
  background-color: transparent;
  border-color: transparent;
  border-width: 0px;
}
.breadcrumb{
  background-color: transparent;
  padding: 0px !important;
  margin-bottom: 0px !important;
  .breadcrumb > li + li:before {
    color: #ccc;
    content: "";
    padding: 0 5px;
  }
}
.clientsPriceList{
    cursor: pointer;
    :hover{
        background-color: #ebf0fa
    }
}
.pinIcons{
  color:white;
  width:22px;
  height:22px;
  border-radius: 7px;
}
.pin{
  background-color: #0062cc;
  border-width: medium;
  border-color: white;
  border-style: solid;
  border-radius: 50%;
  // opacity: 0.3;
}  
// .pin:hover{
//   opacity: 1;
// }
.plantAvatar{
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.mapButtons{
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 0px;
  right: 0px;
}
.switchContainer{
  padding-left: 0px !important;
  padding-right: 0px !important;
  // background-color: #fafafb !important;
}
.hrVer{   
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%,100);
  border-color: #e6e6e6;
  height: 7vh;
  width: 1px;
  margin: 0px 15px 15px 15px;
}
.loggedAvatar{
  width: 50px;
  height: 50px;
  border-radius: 30px;
  margin-right: 10px;
}
.poppinsFont{
  font-family: 'Poppins', sans-serif !important;
}
.robotoFont{
  font-family: 'Roboto', sans-serif;
}
// .logo{
//   background-image: url("./assets/logo.png");
//   background-position: center;
//   background-size: cover;
//   width: 201px;
//   height: 34px;
// }
.right{
  -webkit-transform:rotate(270deg);
}
.detailText{
  font-size: 12px;
}
.autosuggest{
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  // width: 230px;
  // height: 40px;
}
.autosuggestFilter{
  border: 1px solid #bfbfbf;
  border-radius: 14px;
  background-color: #f2f2f2;
  width: 250px;
  height: 40px;
}
.autosuggestProfile{
  border: 1px solid #bfbfbf;
  border-left: none;
  border-top: none;
  border-right: none;
  // border-radius: 14px;
  background-color: transparent;
  width: 184px;
  height: 40px;
}
.autosuggestFilterBorderBottom{
  border: 2px solid #bfbfbf;
  border-left: none;
  border-top: none;
  border-right: none;
  // border-radius: 14px;
  background-color: transparent;
  width: 100%;
  height: 40px;
}
// .autosuggest{
//   border: 1px solid #bfbfbf;
//   border-radius: 10px;
// }
// .autosuggest input{
//   width: 165px;
//   height: 38px;
// }
//// NASCONDERE FRECCE NUMERI IN INPUT
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.hoverPointer{
  :hover{
    cursor: pointer;
  }
}
.hoverPointerDisabled{
  :hover{
    cursor: default;
  }
}
.selectedPlanningType{
  color: #0062cc;
  border-left-color: #0062cc;
  border-left-width: 7px;
  border-left-style: solid;
  border-radius: 0px 10px 10px 0px;
}
.super-cluster {
  color: #fff;
  background: #0062FF;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20px;
  right: -20px;
  font-size: 14px;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 98, 255, 0.25);
}
.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cluster-marker-false {
  cursor: pointer;
  border: 4px solid white;
  background: #bfbfbf;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cluster-marker-true {
  border: 4px solid white;
  background: #00e68a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.planningHeight{
  // height: 78vh !important;
  height: calc(100vh - 192px);
}
.planningHeightGuided{
  height: calc(100vh - 192px);
  // height: 83vh !important;
}
.overviewDetailRow{
  background-color: #66b0ff;
  color: white;
}
.billaloIcon{
  background-image: url('./assets/Billalo_logo.png');
  background-size: cover;
  width: 40px;
  height: 40px;
}
.maintenanceOverview{
  background-color: #e8fff6;
  color: #00e68a;
  padding: 5px;
  border-radius: 10px;
}
.iconOverview{
  background-color: #e6f2ff;
  border-color: transparent;
  border-radius: 10px;
}
.selectedPlantCampain{
  background-color: "green";
  border-radius: 30px;
  width: 40px;
  height: 35px;
}
.middleElement{
  width: 380px !important;
}

///PERSONALIZZARE SIDEBAR
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3; 
}
/////////////////////////
.selectedPage{
  color: #0062cc;
  padding-bottom: 10px;
  border-bottom: 3px solid #0062cc;
}
.campaignListPadding{
  padding: 30px 50px 0px 50px;
}
.overflowCampaignList{
  overflow-x: hidden !important ;
}
.borderSelected{
  border: 1px solid #00e68a;
  border-radius: 14px;
}
.backgroundSelected{
  color: white;
  background-color: #00e68a !important;
}
.orderDetailsLeft{
  margin-top: 20px;
  // background-color: white;
  border-radius: 10px;
  // width: 380px !important;
  // height: 760px;
  overflow-x: auto;
}
*:focus {
  outline: 0 !important;
}
// .StripeElement{   /// con questo si può leggermente modificare qualcosa di stripe, anche se poco
//   background-color: red !important;
// }
.MuiTooltip-popper{
  .MuiTooltip-tooltip{
    display: flex;
    align-items: "center";
    background-color: white;
    border-width: 1px;
    border-color: #f1f1f5;
    border-style: solid;
    border-radius: 5px;
    padding: 15px;
    font-size: 15px;
  }
}
.MuiPopover-paper{ /// bordi popover
border-radius: 14px !important;
}
// .MuiStepLabel-iconContainer {
//   background-color: rgba(238, 245, 232, 1),
// }
.newAdvancedPlanningIcon {
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: rgba(255, 197, 66, 0.1);
  border-radius: 50%;
}
.notificationTransition {
  height: 100px !important;
}